.wrapper .title{
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
    text-align: center;
}

.wrapper div{
    max-height: 35px;
    overflow: hidden;
    padding-left: 15px;
}

.wrapper div.active{
    transition: max-height 0.25s ease-in;
}
.wrapper a.toggle_text{
    color: #ff708a;
}
.wrapper a.toggle_text:hover{
    filter: brightness(85%);
}

.description-container {
    background-color: #95d3c7;
    padding: 25px;
    border-radius: 5px;
}
